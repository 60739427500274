import { client } from ".";

const size = process.env.REACT_APP_PAGESIZE;

export const getRetailLoans = (query) => {
  return client.get(`loans/retail`, { params: { ...query, size } });
};

export const getLoans = (query) => {
  return client.get(`/loans`, { params: { ...query, size } });
};

export const getSmeLoans = (query) => {
  return client.get(`/sme/loans`, { params: { ...query, size } });
};

export const getLoanStatById = (loanUid) => {
  return client.get(`/loan/details?loanUid=${loanUid}`);
};

export const getLoan = (loanUid) => {
  return client.get(`loans/${loanUid}`);
};

export const getLoanRepaymentHistory = ({ loanId, page }) => {
  return client.get(
    `/repayment/history?loanUid=${loanId}&page=${page}&size=${size}`
  );
};
export const getRetailLoanHistory = ({ userUid, page }) => {
  return client.get(
    `loans/retail/history/${userUid}?page=${page}&size=${size}&filterBy`
  );
};

export const getLoanPenalty = ({ loanId, page }) => {
  return client.get(
    `loan/penalties?loanUid=${loanId}&page=${page}&size=${size}`
  );
};

export const getSmeLoanHistory = ({ businessId, page }) => {
  return client.get(
    `sme/loans/history?businessId=${businessId}&page=${page}&size=${size}`
  );
};

export const getLoanDisburseMent = ({ loanId, page }) => {
  return client.get(
    `/disbursements/loan/fetch?loanUid=${loanId}&page=${page}&size=${size}`
  );
};

export const searchLoans = (page, search) => {
  return client.get(`loans/retail?search=${search}&page=${page}&size=${size}`);
};

export const setLoanRecommender = (loanUid) => {
  return client.patch(`loans/${loanUid}/set-recommender`);
};

export const recommendLoanOffer = ({
  loanUid,
  recommendOffer,
  recommendedAmount,
  recommendedInterestRate,
  recommendedTenure,
  reasonsForRecommendation,
}) => {
  return client.patch(`loans/recommend-offer`, {
    loanUid,
    recommendOffer,
    recommendedAmount,
    recommendedInterestRate,
    recommendedTenure,
    reasonsForRecommendation,
  });
};

export const setLoanOfferer = (loanUid) => {
  return client.patch(`loans/${loanUid}/set-offerer`);
};

export const offerLoan = ({
  loanUid,
  isLoanOffered,
  loanOfferAmount,
  loanOfferInterestRate,
  loanOfferTenure,
  reasonsForLoanRequestDecision,
}) => {
  return client.patch("loans/process-loan-decision/retail", {
    loanUid,
    isLoanOffered,
    loanOfferAmount,
    loanOfferInterestRate,
    loanOfferTenure,
    reasonsForLoanRequestDecision,
  });
};

export const internationalPassport = ({ image, loanUid }) => {
  return client.patch(
    "loans/international-passport",
    { image, loanUid },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const lienDocuments = ({
  loanUid,
  adminOverseeingLoan,
  adminPlacingLien,
  files,
  lienBankAccountName,
  lienBankAccountNumber,
  lienBankName,
  lienBankSortCode,
}) => {
  return client.post(
    "loans/upload-lien-documents",
    {
      loanUid,
      adminOverseeingLoan,
      adminPlacingLien,
      files,
      lienBankAccountName,
      lienBankAccountNumber,
      lienBankName,
      lienBankSortCode,
    },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const disburse = ({ loanUid, accountNumber, amount }) => {
  return client.post(`disburse`, { loanUid, accountNumber, amount });
};

export const disburseSme = ({ loanUid, amount }) => {
  return client.post(`disburse/sme`, { loanUid, amount });
};

export const uploadProofOfRepayment = ({ loanUid, proofOfRepayment }) => {
  return client.post(
    "loans/proof-of-repayment",
    { loanUid, proofOfRepayment },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const getLoanBankStatement = (loanUid) => {
  return client.get(`/bankstatement/${loanUid}`);
};
