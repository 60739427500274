export const businessLoanFilters = {
  ALL: "",
  PENDING_RECOMMENDATION: "PENDING_RECOMMENDATION",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  PENDING_OFFER: "PENDING_OFFER",
  PENDING_DISBURSEMENT: "PENDING_DISBURSEMENT",
  OFFERED: "OFFERED",
  ACTIVE: "ACTIVE",
  DEFAULTING: "DEFAULTING",
  COMPLETED: "COMPLETED",
  NTU: "NTU",
  PENDING_AUTHORIZATION: "PENDING_AUTHORIZATION",
  LOAN_REJECTED: "LOAN_REJECTED", //loan rejected by team member
  UNDER_REVIEW: "UNDER_REVIEW",
  APPROVED: "APPROVED",
  OFFER_ACCEPTED: "OFFER_ACCEPTED",
  OFFER_REJECTED: "OFFER_REJECTED", //offer declined by client
  REQUEST_DECLINED: "REQUEST_DECLINED",
  PENDING_LIEN: "PENDING_LIEN",
  PENDING_DEPOSIT: "PENDING_DEPOSIT",
  PAY_INTEREST: "PAY_INTEREST",
  LOST: "LOST",
};
