import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { styles } from "../../common/css/styles";
import { useNavigate } from "react-router-dom";

import { DeadState } from "../../common/components/DeadState";
import { PageNavigation } from "../../common/components/PageNavigation";
import { useSelector } from "react-redux";
import { ProceedToRecommend } from "./components/ProceedToRecommend";
import { ProceedToOffer } from "./components/ProceedToOffer";
import { PreventOfferAttempt } from "./components/PreventOfferAttempt";
import { DenyLoanView } from "./components/DenyLoanView";
import { loanStatusFilters } from "./constants/loanFilters";
import { formatCurrencyvalue, getLoanStatusDisplay } from "../../common/util";
import { format } from "date-fns";

const loanLabels = {
  RETAIL: "Retail Loan ",
  BUSINESS: "Business Loan",
  ASSET_FINANCING: "Asset Financing",
  BRIDGE_FINANCING: "Bridge Financing",
};

export const LoansTableNew = ({
  retailLoans,
  page,
  handlePageChange,
  loading,
  loanStatus,
  selectedLoanType,
  search,
  // handleView,
}) => {
  const [openProceedToRecommend, setOpenProceedToRecommend] = useState(false);
  const [openProceedToOffer, setOpenProceedToOffer] = useState(false);
  const [openPreventOfferAttempt, setOpenPreventOfferAttempt] = useState(false);
  const [openDenyLoanView, setOpenDenyLoanView] = useState(false);
  const navigate = useNavigate();
  const loans = retailLoans.loans;

  const user = useSelector(({ auth }) => auth.user);

  const canBeAssignedRecomender = (loan) => {
    const roles = user.roles ?? [];
    const { type } = loan;

    const loanType =
      type === "BUSINESS" || type === "ASSET_FINANCING" ? "sme" : "retail";

    if (
      loanType === "retail" &&
      (roles.some((v) => v.name === "MANAGER") ||
        roles.some((v) => v.name === "CHECKER"))
    ) {
      return true;
    }
    // sme and is a manager
    if (loanType === "sme" && roles.some((v) => v.name === "MANAGER")) {
      return true;
    }

    return false;
  };
  const canBeAssignedOfferer = (loan) => {
    const roles = user.roles ?? [];
    const { type } = loan;

    const loanType =
      type === "BUSINESS" || type === "ASSET_FINANCING" ? "sme" : "retail";

    if (loanType === "retail" && roles.some((v) => v.name === "MANAGER")) {
      return true;
    }

    if (loanType === "sme" && roles.some((v) => v.name === "APPROVER")) {
      return true;
    }

    return false;
  };

  const activeLoan =
    loanStatus === loanStatusFilters.ACTIVE ||
    loanStatus === loanStatusFilters.COMPLETED ||
    loanStatus === loanStatusFilters.NTUS ||
    loanStatus === loanStatusFilters.LOST;

  const allcolumns = [
    { id: "uid", label: "Loan UID", minWidth: 50, visible: true },
    {
      id: "email",
      label: "Email",
      minWidth: 50,
      visible: true,
      render: (loan) => loan.customerEmail ?? loan.email,
    },
    {
      id: "loanAmount",
      label: "Requested Amount",
      minWidth: 50,
      visible: true,
      render: (loan) => formatCurrencyvalue(loan.loanAmount),
    },
    {
      id: "recommender",
      label: "Recommender",
      minWidth: 20,
      visible: true,
      render: (loan) =>
        loan.recommender ? (
          <span style={{ textTransform: "capitalize" }}>
            {`${loan.recommender?.firstName} ${loan.recommender?.lastName}`}
          </span>
        ) : (
          "Unassigned"
        ),
    },
    {
      id: "offerer",
      label: "Offerer",
      minWidth: 20,
      visible: true,
      render: (loan) =>
        loan.offerer ? (
          <span style={{ textTransform: "capitalize" }}>
            {`${loan.offerer?.firstName} ${loan.offerer?.lastName}`}
          </span>
        ) : (
          "Unassigned"
        ),
    },
    {
      id: "loanType",
      label: "Loan Type",
      minWidth: 50,
      visible: true,
      render: (loan) => (
        <span style={{ whiteSpace: "nowrap" }}>{loanLabels[loan.type]}</span>
      ),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 20,
      visible: true,
      render: (loan) => getLoanStatusDisplay(loan.status),
    },
    {
      id: " ",
      label: "Date Created",
      minWidth: 20,
      visible: true,
      render: (loan) => (
        <span
          style={{
            ...styles.table.text,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {format(new Date(loan?.createdAt), "dd/MM/yyyy hh:mm:ss aa")}
        </span>
      ),
    },

    {
      id: "view",
      label: "View",
      minWidth: 50,
      visible: true,
      render: (loan) => (
        // canAct(loan) ? (
        <Button
          variant="outlined"
          onClick={() => handleView(loan)}
          sx={styles.table.text.viewButton}
        >
          View
        </Button>
      ),
      // ) : null,
    },
  ];

  const activecolumns = [
    { id: "uid", label: "Loan UID", minWidth: 50, visible: true },
    {
      id: "email",
      render: (loan) => loan.customerEmail ?? loan.email,
      label: "Email",
      minWidth: 50,
      visible: true,
    },
    {
      id: "loanOfferAmount",
      label: "Amount Disbursed",
      minWidth: 50,
      visible: true,
      render: (loan) => formatCurrencyvalue(loan.amountDisbursed),
    },
    {
      id: "amountPaid",
      label: "Paid",
      minWidth: 50,
      visible: true,
      render: (loan) => formatCurrencyvalue(loan.amountPaid),
    },
    {
      id: "outstanding",
      label: "Outstanding",
      minWidth: 50,
      visible: true,
      render: (loan) => formatCurrencyvalue(loan.amountOutstanding),
    },
    {
      id: "recommender",
      label: "Recommender",
      minWidth: 20,
      visible: true,
      render: (loan) =>
        loan.recommender
          ? `${loan.recommender?.firstName} ${loan.recommender?.lastName}`
          : "Unassigned",
    },
    {
      id: "loanType",
      label: "Loan Type",
      minWidth: 50,
      visible: true,
      render: (loan) => (
        <span style={{ whiteSpace: "nowrap" }}>{loanLabels[loan.type]}</span>
      ),
    },
    {
      id: " ",
      label: "Date Created",
      minWidth: 20,
      visible: true,
      render: (loan) => (
        <span>
          {format(new Date(loan?.createdAt), "dd/MM/yyyy hh:mm:ss aa")}
        </span>
      ),
    },

    {
      id: "view",
      label: "View",
      minWidth: 50,
      visible: true,
      render: (loan) => (
        // canAct(loan) ? (
        <Button
          variant="outlined"
          onClick={() => handleView(loan)}
          sx={styles.table.text.viewButton}
        >
          View
        </Button>
      ),
    },
  ];

  const columns = activeLoan ? activecolumns : allcolumns;
  const columnsToShow = columns.filter((v) => {
    if (loanStatus === "ALL" && v.id === "view") return false;
    if (loanStatus !== "ALL" && v.id === "status") return false;
    if (selectedLoanType !== "ALL" && v.id === "loanType") return false;
    return true;
  });

  const handleView = (loan) => {
    const { recommender, status, type, uid, offerer } = loan;

    const loanType =
      type === "BUSINESS" || type === "ASSET_FINANCING" ? "sme" : "retail";

    if (
      status === loanStatusFilters.ACTIVE ||
      status === loanStatusFilters.COMPLETED ||
      status === loanStatusFilters.NTU ||
      status === loanStatusFilters.LOST ||
      status === loanStatusFilters.DEFAULTING
    ) {
      navigate(`/loans/loan-details/${loanType}/${uid}`);
    } else if (status === loanStatusFilters.PENDING_RECOMMENDATION) {
      if (!recommender && canBeAssignedRecomender(loan)) {
        setOpenProceedToRecommend(loan);
      } else {
        navigate(`/loans/overview/${loanType}/${uid}`);
      }
      //  else if (recommender.uid === user.uid) {
      //   navigate(`/loans/overview/${loanType}/${uid}`);
      // } else {
      //   setOpenDenyLoanView(true);
      // }
    } else if (status === loanStatusFilters.PENDING_OFFER) {
      if (
        !offerer &&
        canBeAssignedOfferer(loan) &&
        recommender.uid !== user.uid
      ) {
        setOpenProceedToOffer(loan);
      } else {
        if (recommender.uid === user.uid) {
          setOpenPreventOfferAttempt(true);
        } else {
          navigate(`/loans/overview/${loanType}/${uid}`);
        }

        // .......

        // ..........................
        // if (!offerer && canBeAssignedOfferer(loan)) {
        //   setOpenProceedToOffer(loan);
        // } else {
        //   if (recommender.uid === user.uid) {
        //     setOpenPreventOfferAttempt(true);
        //   } else {
        //     navigate(`/loans/overview/${loanType}/${uid}`);
        //   }
        // else if (offerer?.uid === user.uid) {
        //   navigate(`/loans/overview/${loanType}/${uid}`);
        // } else {
        //   setOpenDenyLoanView(true);
        //   // navigate(`/loans/overview/${loanType}/${loan.uid}`);
        // }
      }
    } else {
      navigate(`/loans/overview/${loanType}/${loan.uid}`);
    }
  };

  const filteredLoans = loans
    ?.map((loan) => ({ ...loan, searchString: JSON.stringify(loan) }))
    .filter((loan) =>
      loan.searchString?.toLowerCase()?.includes(search?.toLowerCase())
    );

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400,
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "#000",
                  backgroundColor: "#fff",
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  borderColor: "#B5B5B5",
                  py: 2,
                },
              }}
            >
              {columnsToShow
                ?.filter((column) => column.visible === true)
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        color: "#000",
                        backgroundColor: "#fff",
                        fontSize: 13,
                        fontWeight: 600,
                      }}
                    >
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableCell
                colSpan={columns.length}
                sx={{ textAlign: "center", borderBottom: 0, pt: 4 }}
              >
                <CircularProgress />
              </TableCell>
            ) : filteredLoans?.length > 0 ? (
              filteredLoans?.map((loan) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={loan.uid}
                  sx={{
                    "& .MuiTableCell-body": {
                      color: "#000",
                      backgroundColor: "#fff",
                      borderBottom: 0.5,
                      borderColor: "#F7F8F4",
                    },
                  }}
                >
                  {columnsToShow.map((v, i) => (
                    <TableCell key={i} align="left">
                      <Typography sx={styles.table.text.uid}>
                        {v.render ? v.render(loan) : loan[v.id]}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableCell
                colSpan={columnsToShow?.length}
                sx={{ textAlign: "center" }}
              >
                <DeadState />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!loading && filteredLoans?.length > 0 ? (
        <PageNavigation
          page={page}
          totalRecords={retailLoans.totalRecords}
          handlePageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
      <ProceedToRecommend
        open={openProceedToRecommend}
        handleClose={() => setOpenProceedToRecommend(false)}
        loan={openProceedToRecommend}
      />
      <ProceedToOffer
        open={openProceedToOffer}
        handleClose={() => setOpenProceedToOffer(false)}
        loan={openProceedToOffer}
      />

      {/* <ProceedToAction
        open={openProceedToAction}
        handleClose={() => setOpenProceedToAction(false)}
        role={role}
      /> */}
      <PreventOfferAttempt
        open={openPreventOfferAttempt}
        handleClose={() => setOpenPreventOfferAttempt(false)}
      />
      <DenyLoanView
        open={openDenyLoanView}
        handleClose={() => setOpenDenyLoanView(false)}
      />
    </Box>
  );
};
