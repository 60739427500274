import { useQuery } from "@tanstack/react-query";
import { getLoan } from "../../../common/api/retail";
import { getWallets } from "../../../common/api/customers";

export const useRetailLoans = (loanid) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [loanid],
    queryFn: () => getLoan(loanid),
    refetchOnWindowFocus: false,
  });

  const loan = data?.data?.data ?? {};
  const { data: walletData, isLoading: isWalletLoading } = useQuery({
    queryKey: [loan],
    queryFn: () => getWallets(loan?.user?.uid),
    refetchOnWindowFocus: false,
  });
  loan.wallet = walletData?.data?.data;

  return {
    loan,
    refetch,
    anyLoading: isLoading || isWalletLoading,
  };
};
