export const loanStatusFilters = {
  ALL: "ALL",
  PENDING_RECOMMENDATION: "PENDING_RECOMMENDATION",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  PENDING_AUTHORIZATION: "PENDING_AUTHORIZATION",
  PENDING_REVIEW: "PENDING_REVIEW",
  PENDING_DISBURSEMENT: "PENDING_DISBURSEMENT",
  PENDING_LIEN: "PENDING_LIEN",
  PENDING_OFFER: "PENDING_OFFER",

  PAY_DEPOSIT: "PAY_DEPOSIT",

  OFFERED: "OFFERED",
  OFFER_ACCEPTED: "OFFER_ACCEPTED",
  ACTIVE: "ACTIVE",
  PAY_INTEREST: "PAY_INTEREST",

  OFFER_REJECTED: "OFFER_REJECTED",
  DEFAULTING: "DEFAULTING",
  DECLINED: "DECLINED",

  COMPLETED: "COMPLETED",
  LOST: "LOST",
  NTU: "NTU",
};

export const businessLoanStages = [loanStatusFilters.PENDING_APPROVAL];

// ............................

export const bussinessLoansFilters = {
  ALL: "",
  APPLICATION_STATUS: "APPLICATION_STATUS",
  PENDING_RECOMMENDATION: "PENDING RECOMMENDATION",
  PENDING_OFFER: "PENDING OFFER",
  OFFERED: "OFFERED",
  ACTIVE: "ACTIVE",
  REJECTED_OFFERS: "REJECTED_OFFERS",
  DEFAULTING: "DEFAULTING",
  DECLINED: "DECLINED",
  COMPLETED: "COMPLETED",
  NTUS: "NTUS",
};

export const assetLoansFilters = {
  ALL: "",
  APPLICATION_STATUS: "APPLICATION_STATUS",
  PENDING_RECOMMENDATION: "PENDING RECOMMENDATION",
  PENDING_OFFER: "PENDING OFFER",
  OFFERED: "OFFERED",
  ACTIVE: "ACTIVE",
  REJECTED_OFFERS: "REJECTED_OFFERS",
  DEFAULTING: "DEFAULTING",
  DECLINED: "DECLINED",
  COMPLETED: "COMPLETED",
  NTUS: "NTUS",
};

export const retailLoansStatusFilters = {
  ALL: "ALL",
  PENDING_RECOMMENDATION: "PENDING_RECOMMENDATION",
  PENDING_DISBURSEMENT: "PENDING_DISBURSEMENT",
  PENDING_OFFER: "PENDING_OFFER",
  PENDING_LIEN: "PENDING_LIEN",
  PAY_INTEREST: "PAY_INTEREST",
  OFFERED: "OFFERED",
  ACTIVE: "ACTIVE",
  OFFER_REJECTED: "OFFER_REJECTED",
  DEFAULTING: "DEFAULTING",
  DECLINED: "DECLINED",
  COMPLETED: "COMPLETED",
  NTUS: "NTUS",
  LOST: "LOST",
};

export const bridgeFinanceFilters = {
  ALL: "ALL",
  PENDING_RECOMMENDATION: "PENDING_RECOMMENDATION",
  PENDING_OFFER: "PENDING_OFFER",
  PENDING_LIEN: "PENDING_LIEN",
  DECLINED: "REQUEST_DECLINED",
  OFFERED: "OFFERED",
  OFFER_REJECTED: "OFFER_REJECTED",
  PAY_INTEREST: "PAY_INTEREST",
  ACTIVE: "ACTIVE",
  DEFAULTING: "DEFAULTING",
  NTU: "NTU",
  COMPLETED: "COMPLETED",
  PENDING_DISBURSEMENT: "PENDING_DISBURSEMENT",
};

export const loansDetailsFilters = {
  PERSONAL: "PERSONAL",
  RESIDENCE: "RESIDENCE",
  EMPLOYMENT: "EMPLOYMENT",
  BANK_INFO: "BANK_INFO",
  NEXT_OF_KIN: "NEXT_OF_KIN",
  IDENTIFICATION: "IDENTIFICATION",
  DOCUMENT: "DOCUMENT",
};

export const repaymentFilters = {
  SUCCESSFUL: "SUCCESSFUL",
  FAILED: "FAILED",
  PENDING: "PENDING",
};
